<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <filter-card
          :reset-search="resetSearch"
          :search-data="searchData"
        />
      </b-card>
    </b-col>
    <b-col md="12">
      <b-card no-body>
        <b-card-body class="text-right">
          <b-button
            variant="danger"
            @click="showAddModal"
          >
            <FeatherIcon icon="PlusIcon" />
            Oluştur
          </b-button>
        </b-card-body>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >

            <template #cell(name)="data">
              <div>{{ data.item.name }}</div>
              <div class="text-primary font-small-2">
                {{ data.item.company }}
              </div>
            </template>
            <template #cell(kvkk)="data">
              <FeatherIcon
                :icon="data.item.kvkk === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                :class="data.item.kvkk === '1'? 'text-success' : 'text-danger'"
              />
            </template>
            <template #cell(username)="data">
              <div>
                {{ data.item.username }}
              </div>
              <div class="text-primary font-small-2">
                {{ data.item.created ? moment(data.item.created).format('DD.MM.YYYY') : null }}
              </div>
            </template>
            <template #cell(phone)="data">
              {{ data.item.phone }}
            </template>
            <template #cell(control)="data">
              <b-button
                v-if="data.item.kvkk == null || data.item.kvkk == 0"
                variant="outline-primary"
                size="sm"
                @click="showModal(data.item.id)"
              >
                Doküman Yükle
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer class="d-flex align-items-center justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
          <div class="text-muted font-small-2">
            Toplam {{ dataCount | toNumber }} adet müşteri kartı bulundu.
          </div>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-modal
      v-model="uploadModal.status"
      title="Doküman Yükle"
      centered
    >
      <pre-loading v-if="uploading" />
      <template v-else>
        <validation-observer ref="simpleRules">
          <validation-provider
            #default="{ errors }"
            name="Doküman"
            rules="required"
          >
            <b-form-group
              label="Doküman Yükle"
              label-for="upload_file"
            >
              <b-form-file
                id="upload_file"
                v-model="documentData.upload_file"
                placeholder="Bir dosya seçin veya buraya bırakın..."
                drop-placeholder="Dosyayı buraya bırakın..."
                browse-text="Dosya Seçin"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Doküman Tipi"
            rules="required"
          >
            <b-form-group
              v-if="documentData.upload_file"
              label="Doküman Tipi"
              label-for="id_com_customer_document_type"
            >
              <v-select
                id="id_com_customer_document_type"
                v-model="documentData.id_com_customer_document_type"
                label="title"
                :reduce="item => item.id"
                :options="documentTypes"
                placeholder="Doküman Tipi"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </b-form-group>
          </validation-provider>
        </validation-observer>
      </template>
      <template #modal-footer>
        <div class="w-100 d-block text-right">
          <b-button
            variant="success"
            class="text-right"
            :disabled="documentData.submitStatus"
            @click="submitData"
          >
            <FeatherIcon icon="UploadIcon" /> Yükle
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- ADD QUICK CUSTOMER -->

    <b-modal
      v-model="addModal.status"
      title="Yeni Müşteri Kartı"
      centered
    >
      <pre-loading v-if="uploading" />
      <template v-else>
        <validation-observer ref="simpleRules">
          <validation-provider
            #default="{ errors }"
            name="Adı Soyadı"
            rules="required"
          >
            <b-form-group
              label="Adı Soyadı"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="addData.name"
                placeholder="Giriniz"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Telefon"
            rules="required"
          >
            <b-form-group
              label="Telefon"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="addData.phone"
                placeholder="Giriniz"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Doküman"
            rules="required"
          >
            <b-form-group
              label="Doküman Yükle"
              label-for="upload_file"
            >
              <b-form-file
                id="upload_file"
                v-model="addData.upload_file"
                placeholder="Bir dosya seçin veya buraya bırakın..."
                drop-placeholder="Dosyayı buraya bırakın..."
                browse-text="Dosya Seçin"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Doküman Tipi"
            rules="required"
          >
            <b-form-group
              v-if="addData.upload_file"
              label="Doküman Tipi"
              label-for="id_com_customer_document_type"
            >
              <v-select
                id="id_com_customer_document_type"
                v-model="addData.id_com_customer_document_type"
                label="title"
                :reduce="item => item.id"
                :options="documentTypes"
                placeholder="Doküman Tipi"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </b-form-group>
          </validation-provider>
        </validation-observer>
      </template>
      <template #modal-footer>
        <div class="w-100 d-block text-right">
          <b-button
            variant="success"
            class="text-right"
            :disabled="documentData.submitStatus"
            @click="add_Data"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BModal, BFormFile, BFormGroup, BCardBody, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import FilterCard from '@/views/Customers/components/FilterCard.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Index',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormFile,
    PreLoading,
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    FilterCard,
    BModal,
    vSelect,
    BCardBody,
    BFormInput,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      uploadModal: {
        status: false,
        id_com_customer: null,
      },
      addModal: {
        status: false,
      },
      fields: [
        {
          key: 'name',
          label: 'Müşteri',
        },
        {
          key: 'kvkk',
          label: 'KVKK',
          thClass: 'width-100 text-nowrap text-center',
          tdClass: 'width-100 text-nowrap text-center',
        },
        {
          key: 'username',
          label: 'Oluşturma',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'phone',
          label: 'Telefon',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'width-100 text-nowrap text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id AS id',
          'com_customer.name AS name',
          'com_customer.company_name AS company',
          'com_customer.phone AS phone',
          'com_meet.title AS meet',
          'com_customer.kvkk AS kvkk',
          'com_user.name AS username',
          'com_customer.created AS created',
        ],
        where: {
          'com_customer.kvkk !=': 1,
        },
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customers/getCustomers']
    },
    dataCount() {
      return this.$store.getters['customers/getCustomersCount']
    },
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    documentTypes() {
      return this.$store.getters['customerDocumentTypes/getDataList']
    },
    documentData() {
      return this.$store.getters['customerDocuments/getData']
    },
    addData() {
      return this.$store.getters['customerDocuments/getAddData']
    },
    uploading() {
      return this.$store.getters['customerDocuments/uploading']
    },
    saveDataStatus() {
      return this.$store.getters['customerDocuments/saveDataStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
    saveDataStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.uploadModal.status = false
        this.addModal.status = false
        this.uploadModal.id_com_customer = null
        this.getDataList()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.documentData.submitStatus = false
      this.addData.submitStatus = false
    },
  },
  created() {
    this.getDataList()
    this.getUsers()
    this.getDocumentTypes()
  },
  methods: {
    showModal(idComCustomer) {
      this.uploadModal.id_com_customer = idComCustomer
      this.uploadModal.status = true
    },
    showAddModal() {
      this.addModal.name = null
      this.addModal.phone = null
      this.addData.filename = null
      this.addData.upload_file = null
      this.addData.id_com_customer_document_type = '1'
      this.addData.name = null
      this.addData.phone = null
      this.addModal.status = true
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.documentData.submitStatus = true
          this.documentData.id_com_customer = this.uploadModal.id_com_customer
          this.$store.dispatch('customerDocuments/saveData', this.documentData)
        }
      })
    },
    add_Data() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addData.submitStatus = true
          this.$store.dispatch('customerDocuments/AddCustomer', this.addData)
        }
      })
    },
    getDocumentTypes() {
      return this.$store.dispatch('customerDocumentTypes/dataList')
    },
    searchData() {
      const where = {}
      if (this.filterData.keyword.length) {
        this.dataQuery.or_like = {
          'com_customer.name': this.filterData.keyword,
          'com_customer.company_name': this.filterData.keyword,
          'com_customer.phone': this.filterData.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      if (this.filterData.id_com_user) {
        where['com_customer.id_com_user'] = this.filterData.id_com_user
      }
      if (this.filterData.sdate) {
        where['DATE(com_customer.created) >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['DATE(com_customer.created) <='] = this.filterData.edate
      }
      where['com_customer.kvkk !='] = 1
      this.dataQuery.where = where
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('customers/customersList', this.dataQuery)
    },
    getUsers() {
      return this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_brand.name AS brand',
          'com_department.title AS department',
          'com_department.title AS location',
        ],
        where: {
          'com_user.status': 1,
        },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
